import { LoadingButton } from "@mui/lab";
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { verifyPin } from "../../api/AuthService";
import { api } from "../../api/api";
import { useErrorHandler } from "../../common/hooks";
import ConfirmationDialog from "../ConfirmationDialog";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface IOwnProps {
  onNext: () => void;
}

interface IInputValue {
  value: string;
  isDirty: boolean;
}

const VerifyPINStep: React.FC<IOwnProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();

  const [pin, setPin] = useState<IInputValue>({ value: "", isDirty: false });
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [showPIN, setShowPIN] = useState<boolean>(false);
  const [openForgottenPinDialog, setOpenForgottenPinDialog] =
    useState<boolean>(false);

  useEffect(() => {
    const validationResult = validateInput();

    setIsFormValid(validationResult);
  }, [pin]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setPin({ value, isDirty: true });
  };

  const handleSubmitClick = () => {
    setIsLoading(true);

    api(verifyPin(pin.value))
      .then((response) => {
        onNext();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          if (error.response?.data.detail[0].field == "pin") {
            setError(t("error_wrongPin"));
          }
        }

        handleServerError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateInput = () => {
    setError("");

    let isValid = true;

    if (!pin.value) {
      isValid = false;
    }

    if (!pin.value && pin.isDirty) {
      setError(t("enterPin_title"));

      isValid = false;
    }

    if (pin.value && pin.value.length < 6) {
      setError(t("minimum_symbols", { "0": 6 }));

      isValid = false;
    }

    return isValid;
  };

  const handleClickShowPIN = () => {
    setShowPIN((show) => !show);
  }

  return (
    <>
      <Typography variant="h5" component="h5">{t("enterPin_title")}</Typography>
      <Typography variant="body2">{t("enterPin_subtitle")}</Typography>

      <form>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel htmlFor="outlined-adornment-current-pin">
            {t("current_pin")}
          </InputLabel>

          <OutlinedInput
            error={!!error}
            name="currentPin"
            onChange={handleInputChange}
            onKeyPress={(event) => {
              if (/[^0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={pin.value}
            data-test-id="current-pin-textfield"
            type={showPIN ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPIN}
                  edge="end"
                >
                  {showPIN ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={t("current_pin")}
          />

          {!!error && (
            <FormHelperText error id="accountId-error">
              {error}
            </FormHelperText>
          )}
        </FormControl>

        <LoadingButton
          data-test-id="pin-button"
          sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
          onClick={handleSubmitClick}
          disabled={!isFormValid}
          loading={isLoading}
        >
          {isLoading ? t("changePin_validatePinLoadingText") : t("continue_text")}
        </LoadingButton>
      </form>

      <Typography align="center">
        <Button
          data-test-id="forget-pin-button"
          variant="text"
          color="primary"
          size="small"
          sx={{ pl: 2, pr: 2, mt: 2 }}
          onClick={() => setOpenForgottenPinDialog(true)}
        >
          {t("changePin_forgetPin")}
        </Button>
      </Typography>

      {/* Forgotten PIN Dialog */}
      <ConfirmationDialog
        testId="forgotten-pin-dialog"
        maxWidth="sm"
        open={openForgottenPinDialog}
        title={t("auth_forgotPinAlertTitle")}
        message={t("auth_forgotPinAlertMessage")}
        confirmButtonText={t("email_support")}
        cancelButtonText={t("button_close")}
        onConfirm={() => { window.location.href = `mailto:${import.meta.env.VITE_SUPPORT_EMAIL}` }}
        onCancel={() => setOpenForgottenPinDialog(false)}
      />
    </>
  );
};

export default VerifyPINStep;
