import ErrorBox from "../components/Modal/ErrorBox";
import { Typography } from "@mui/material";
import { generateDeclinedDescription } from "../common/helpers";
import { useTranslation } from "react-i18next";
import { configService } from "../services/configService";
import CardContainer from "./CardContainer";

const Support = () => {
  const { t } = useTranslation();

  const supportEmail = configService.config.supportEmail;

  return (
    <CardContainer title={t("tabBar_support")}>
      <ErrorBox data-test-id="support-alert" text={t("helpAndSupport_emergencyWarningDescription")} />
      <Typography
        data-test-id="support-title"
        variant="h6"
        component="h2"
        my={2}
      >
        {t("helpAndSupport_howCanWeHelp")}
      </Typography>
      <Typography
        data-test-id="support-description"
        fontWeight="500"
        variant="body2"
      >
        {generateDeclinedDescription(
          t("help_contactSupport", { "0": supportEmail })
        )}
      </Typography>
    </CardContainer>
  );
};
export default Support;
