import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "@mui/material";

import { supportedLanguages } from "../../i18n";
import { setIsLanguageSelectorDialogOpen } from "../../redux/slices/languageSelectorDialogSlice";

const LanguageButton: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const currentLanguageText = useMemo(() => {
    if (i18n.language) {
      const parts = i18n.language.split("-");
      const language = parts[0].toLowerCase();

      return supportedLanguages[language].name;
    }
  }, [i18n.language]);

  const handleFlagClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setIsLanguageSelectorDialogOpen({ isOpen: true }));
  };

  return (
    <>
      <Button
        data-test-id="language-button"
        onClick={handleFlagClick}
        variant="text"
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ color: "lightGray", fontWeight: "600" }}
      >
        {currentLanguageText}
      </Button>
    </>
  );
};

export default LanguageButton;
