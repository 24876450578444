import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

interface SupportedLanguages {
  [language: string]: {
    regions: string[];
    default: string;
    flagISO2: string;
    name: string;
  };
}

interface LocaleResult {
  locale: string;
  usedFallback: boolean;
}

function getLocale(languageIdentifier: string, supportedLanguages: SupportedLanguages, countryCode?: string): LocaleResult {
  const parts = languageIdentifier.split("-");
  const language = parts[0].toLowerCase();
  const region = parts.length > 1 ? parts[1].toUpperCase() : undefined;

  // First priority is given to the provided countryCode, if valid
  const regionalIdentifier = countryCode ? `${language}-${countryCode.toUpperCase()}` : region ? `${language}-${region}` : language;

  // Check if the language is supported
  if (supportedLanguages[language]) {
    // Check for exact regional match or a match with countryCode
    if (regionalIdentifier && supportedLanguages[language].regions.includes(regionalIdentifier)) {
      return { locale: regionalIdentifier, usedFallback: false };
    }

    // Fallback to the default for the language
    return {
      locale: supportedLanguages[language].default,
      usedFallback: false,
    };
  }

  // Fallback to 'en-GB' if language not supported
  return { locale: "en-GB", usedFallback: true };
}

const customDetector = (lng: string) => {
  const determinedLocale = getLocale(lng, supportedLanguages);

  return determinedLocale.locale;
};

export const supportedLanguages: SupportedLanguages = {
  bg: {
    regions: ["bg"],
    default: "bg",
    flagISO2: "BG",
    name: "Български",
  },
  de: {
    regions: ["de"],
    default: "de",
    flagISO2: "DE",
    name: "Deutsch",
  },
  en: {
    regions: ["en-GB"],
    default: "en-GB",
    flagISO2: "GB",
    name: "English",
  },
  es: {
    regions: ["es-ES"],
    default: "es-ES",
    flagISO2: "ES",
    name: "Español",
  },
  fr: {
    regions: ["fr-FR"],
    default: "fr-FR",
    flagISO2: "FR",
    name: "Français",
  },
  pt: {
    regions: ["pt"],
    default: "pt",
    flagISO2: "PT",
    name: "Português",
  },
  vi: {
    regions: ["vi"],
    default: "vi",
    flagISO2: "VN",
    name: "Tiếng Việt",
  },
  zh: {
    regions: ["zh"],
    default: "zh",
    flagISO2: "CN",
    name: "华语",
  },
};

export const changeUserLanguage = (language: string): LocaleResult => {
  const localeResult = getLocale(language ?? "en-GB", supportedLanguages);

  i18n.changeLanguage(localeResult.locale);

  return localeResult;
};

const i18nInstance = i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en-GB",
    supportedLngs: Object.keys(supportedLanguages).flatMap((lang) => supportedLanguages[lang].regions),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      lookupQuerystring: "lng",
      convertDetectedLanguage: customDetector,
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    react: {
      useSuspense: false,
    },
  });

export default i18nInstance;
