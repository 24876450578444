import { Modal as BaseModal } from "@mui/base/Modal";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { css, styled } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StorageManager from "../services/storage";

interface ProtectYourIdentityProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
}

function ProtectYourIdentity({
  isOpen,
  onClose,
  onContinue,
}: ProtectYourIdentityProps) {
  const { t } = useTranslation();

  const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean }>(
    (props, ref) => {
      const { open, ...other } = props;
      return <div ref={ref} {...other} />;
    }
  );

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const Modal = styled(BaseModal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
  `;

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxWidth: 400,
  };

  const ModalContent = styled("div")(
    ({ theme }) => css`
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 500;
      text-align: start;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
      box-shadow: 0 4px 12px
        ${theme.palette.mode === "dark"
          ? "rgb(0 0 0 / 0.5)"
          : "rgb(0 0 0 / 0.2)"};
      padding: 24px;
      color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

      & .modal-title {
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }

      & .modal-description {
        margin: 0;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
        margin-bottom: 4px;
      }
    `
  );
  const [showAgain, setShowAgain] = useState(false);
  function showAgainCheckboxPressed() {
    const newValue = !showAgain;
    setShowAgain(newValue);
    StorageManager.setProtectYourIdentityMessage(newValue);
  }

  return (
    <Modal
      data-test-id="protect-id-container"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={() => onClose()}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <ModalContent data-test-id="protect-id-content" sx={style}>
        <Typography variant="h5">
          {t("receiptClaim_protectYourIdentity_title")}
        </Typography>
        <Divider></Divider>
        <Typography variant="body1">
          {t("receiptClaim_protectYourIdentity_message")}
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              data-test-id="protect-id-checkbox"
              checked={showAgain}
              onChange={showAgainCheckboxPressed}
              name="termsAndConditions"
              color="primary"
            />
          }
          label={t("receiptClaim_protectYourIdentity_dontShowAgainButtonTitle")}
        />

        <Stack direction="row" justifyContent={"right"} spacing={2} mt={2}>
          <Button
            data-test-id="protect-id-button"
            sx={{ textTransform: "initial", fontWeight: "bold" }}
            variant="contained"
            color="primary"
            onClick={onContinue}
          >
            {t("ok")}
          </Button>
        </Stack>
      </ModalContent>
    </Modal>
  );
}

export default ProtectYourIdentity;
