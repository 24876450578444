import moment from "moment";
import { store } from "../redux/store";
import { AvailablePaymentMethod, VehicleQuestion } from "./paymentTypes";

export interface TransformedVccCardType {
  target_account_id: string;
  target_account_type: string;
  target_account_identifier: string;
  target_account_title: string;
  country_iso: string;
  balance: number;
  currency?: string;
}

export interface Visit {
  name: string;
  window: number;
  timeframe: number;
  is_screening: boolean;
  is_randomisation: boolean;
  description: string;
  id: number;
  is_discontinued: boolean;
  earliest_date: string;
  latest_date: string;
  confirmed_date: string | null;
  categories: KeyValue[];
}

export interface KeyValue {
  key: string;
  value: string;
}

export function dateString(visit: Visit): string | null {
  const hideVisitMetaData =
    store.getState().auth.profile?.hide_visits_meta_info || visit.is_screening;
  if (hideVisitMetaData) return null;
  if (visit.confirmed_date) {
    return formatDateOnly(visit.confirmed_date);
  } else {
    const earliest = formatDateOnly(visit.earliest_date);
    const latest = formatDateOnly(visit.latest_date);
    return `${earliest} - ${latest}`;
  }
}

export interface Token {
  access_token: string;
  expires: number;
  country_iso?: string;
}

export interface Profile {
  claimant_id: number;
  participant_number: string;
  country: string;
  currency: string;
  is_locked: boolean;
  is_displaced: boolean;
  original_country: string | null;
  name: string | null;
  email: string | null;
  is_email_verified: boolean | null;
  preferred_language: string;
  distance_unit: string;
  available_languages: string[];
  available_payment_methods: AvailablePaymentMethod[];
  latest_accepted_tc: Date;
  are_tc_rejected: boolean;
  hide_visits_meta_info: boolean;
  distance_rule_screens: VehicleQuestion[];
  available_claim_types: string[];
}

export interface VisitsResponse {
  visits: Visit[];
}

export function isFutureDate(dateString: string): boolean {
  const date = moment(dateString, "YYYY-MM-DD");
  return date.isAfter();
}

export function formatDateOnly(dateString: string): string {
  const date = moment(dateString, "YYYY-MM-DD");
  return date.format("D MMMM, YYYY");
}

export function formatISODate(dateString: string): string {
  const date = moment(dateString, "YYYY-MM-DDTHH:mm:ss");
  return date.format("D MMMM, YYYY");
}

export const isElectricVehicle = (): boolean => {
  const questions = store.getState().auth.profile?.distance_rule_screens ?? [];
  const electricVehicleQuestion = questions.find(
    (q) => q.property === "is_electric"
  );
  if (
    electricVehicleQuestion &&
    typeof electricVehicleQuestion.selected_option?.value === "boolean"
  ) {
    return electricVehicleQuestion.selected_option.value;
  }
  return false;
};

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const cleanEmptyProperties = (obj: any, ignoreKey: string = "") => {
  let cleanedObj = Object.entries(obj)
    .filter(([key, value]) => {
      return (
        key !== ignoreKey &&
        value !== null &&
        value !== undefined &&
        value !== ""
      );
    })
    .reduce((acc: any, [key, value]) => ({ ...acc, [key]: value }), {});

  return cleanedObj;
};

export interface Carer {
  name: string | null;
  code: string;
  claimant_id: number;
  cares_for: number;
  is_locked: boolean;
  can_delete: boolean;
  is_displaced: boolean;
  original_country: string | null;
}

export interface CarersResponse {
  carers: Carer[];
}
