import i18n from "i18next";

type LanguageVersions = {
  [key: string]: {
    pdf: string;
    content: string;
  };
};

const languageVersions: LanguageVersions = {
  enUS: {
    pdf: "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com/en-gb.pdf",
    content:
      "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com/summary_tandcs_en-gb.html",
  },
  bgBG: {
    pdf: "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com/bg.pdf",
    content:
      "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com/summary_tandcs_bg.html",
  },
  ptPT: {
    pdf: "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com/pt.pdf",
    content:
      "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com/summary_tandcs_pt.html",
  },
  es: {
    pdf: "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com/es-es.pdf",
    content:
      "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com/summary_tandcs_es-es.html",
  },
};

export const getTermsAndConditionsLinks = () => {
  let language = i18n.language;
  language = language?.replace("-", "") ?? "";

  return languageVersions[language] ?? languageVersions["enUS"];
};
