// LoginPage.tsx
import GetAppIcon from "@mui/icons-material/GetApp";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import axios, { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";
import {
  SetPinData,
  carerLoginService,
  participantLoginService,
  setPinService,
} from "../api/AuthService";
import { api } from "../api/api";
import { acceptTermsAndConditions } from "../api/termsAndConditionsServices";
import { getProfile } from "../api/userService";
import { ErrorDetails, getErrorDetails } from "../common/CustomErrorBoundary";
import { getTermsAndConditionsLinks } from "../common/TermsAndConditionsContentProvider";
import { useErrorHandler } from "../common/hooks";
import LanguageButton from "../components/AppHeader/LanguageButton";
import FormErrorBox from "../components/PaymentMethods/FormErrorBox";
import StudyIDDialog from "../components/StudyIDDialog";
import { setProfile } from "../redux/slices/authSlice";
import { reset } from "../redux/store";
import StorageManager from "../services/storage";
import { Token } from "../types/common";
import { remapPaymentMethods } from "../types/paymentTypes";
import CardContainer from "./CardContainer";
import TermsAndConditions from "./TermsAndConditionsPage";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface ServerResponse<T> {
  url: string;
  data?: T;
  error?: AxiosError;
}

const defaultRedirectPath = "/payments";
const pathToIgnore = "/submit/";

export default function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleServerError } = useErrorHandler();
  const location = useLocation();

  const [isNewUser, setIsNewUser] = useState(true);
  const [code, setCode] = useState("");
  const [userType, setUserType] = useState("participant");
  const [participantId, setParticipantId] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [serverURLs, setServerURLs] = useState<string[]>([]);
  const [openStudyIDDialog, setOpenStudyIDDialog] = useState(false);
  const [studyID, setStudyID] = useState("");
  const [error, setError] = useState<ErrorDetails | null>(null);
  const [openForgottenPinDialog, setOpenForgottenPinDialog] =
    useState<boolean>(false);
  const [showPIN, setShowPIN] = useState(false);
  const state: { isInactive?: boolean; from?: Location } = location.state || {};
  const isInactivityRedirect = state.isInactive ?? false;
  let callbackURL: string = state.from?.pathname ?? defaultRedirectPath;

  if (callbackURL && callbackURL.toString().includes(pathToIgnore)) {
    callbackURL = defaultRedirectPath;
  }

  useEffect(() => {
    if (isInactivityRedirect) {
      const loginInformation = StorageManager.getLoginInformation();
      setIsNewUser(false);
      setTermsAccepted(true);
      setUserType(loginInformation?.userType || "participant");
      setParticipantId(loginInformation?.participant_number || "");
      setYearOfBirth(loginInformation?.year_of_birth?.toString() || "");
      setCode(loginInformation?.code || "");
    }
  }, []);

  useEffect(() => {
    const fetchServerURLs = async () => {
      try {
        const response = await axios.get(import.meta.env.VITE_NMIBLE_SERVERS);

        setServerURLs(response.data.urls);
      } catch (error) {
        console.error("Failed to fetch server URLs", error);
      }
    };

    fetchServerURLs();
  }, []);

  useEffect(() => {
    reset();
    if (!isInactivityRedirect) StorageManager.clear();
  }, []);

  const closeStudyIDDialog = () => {
    setOpenStudyIDDialog(false);
    setStudyID("");
  };

  const handleStudyIDSubmit = () => {
    if (studyID.trim() === "") {
      return;
    }

    login();
    closeStudyIDDialog();
  };

  const handleOpenTermsDialog = () => {
    setOpenTermsDialog(true);

    return false;
  };

  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const handleUserTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserType((event.target as HTMLInputElement).value);
  };

  const handleDownloadPdf = () => {
    window.open(getTermsAndConditionsLinks().pdf, "_blank");
  };

  const handleClickShowPIN = () => {
    setShowPIN((show) => !show);
  };

  const isFormValid = useMemo(() => {
    const trimmedParticipantId = participantId.trim();
    const result =
      trimmedParticipantId !== "" &&
      trimmedParticipantId.length >= 5 &&
      (userType === "participant"
        ? yearOfBirth.trim() !== ""
        : code.trim() !== "") &&
      pin.trim() !== "" &&
      (isNewUser ? confirmPin.trim() !== "" && pin === confirmPin : true) &&
      termsAccepted;

    return result;
  }, [
    participantId,
    confirmPin,
    yearOfBirth,
    code,
    pin,
    isNewUser,
    termsAccepted,
  ]);

  const handleLoginAttempt = async (
    url: string,
    loginData: any
  ): Promise<ServerResponse<Token>> => {
    try {
      const config =
        userType === "carer"
          ? carerLoginService(loginData, url)
          : participantLoginService(loginData, url);

      config.headers = {
        "Accept-Language": i18n.language.toLowerCase(),
      }

      const response = await axios(config);

      return { url, data: response.data };
    } catch (error) {
      return {
        url,
        error: error as AxiosError,
      };
    }
  };

  const prioritizeErrors = (errors: AxiosError[]): AxiosError => {
    if (errors.length === 1) {
      // If there's only one error, return it directly
      return errors[0];
    }

    // Look for errors with code 409
    const errorsWith409 = errors.filter((error) => {
      const data: any = error.response?.data;
      return data.err_code === 409;
    });

    // If there's any error with code 409, return the first one
    if (errorsWith409.length > 0) {
      return errorsWith409[0];
    }

    // As a default, return the first error if no 409 errors are found
    return errors[0];
  };

  const executeLoginAttempts = async (loginData: any) => {
    setLoading(true);

    const promises = serverURLs.map((url) =>
      handleLoginAttempt(url, loginData)
    );

    const results = await Promise.all(promises);

    const successResponses = results.filter((result) => result.data);

    const failedResponses = results
      .filter((result) => result.error)
      .map((result) => result.error!);

    let error: AxiosError | undefined;
    let errorCode: number | undefined;

    if (failedResponses.length > 0) {
      error = prioritizeErrors(failedResponses);

      const errorData: any = error.response?.data;
      errorCode = errorData.err_code;
    }

    if (successResponses.length > 1 || errorCode === 409) {
      setOpenStudyIDDialog(true);
      setLoading(false);
    } else if (successResponses.length === 1) {
      const { url, data } = successResponses[0];
      if (!data) return;
      StorageManager.setTokenData(data);
      StorageManager.setServerURL(url);
      api.defaults.baseURL = url;
      performPostLoginActions();
    } else if (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const errorHandler = (error: AxiosError) => {
    const errorDetails = getErrorDetails(error, t);

    if (errorDetails) {
      setError(errorDetails);
    }
  };

  const login = () => {
    const loginData = {
      participant_number: participantId.trim(),
      pin: isNewUser ? undefined : pin.trim(),
      protocol_number: studyID !== "" ? studyID : undefined,
      ...(userType === "carer" && { code: code.trim() }),
      ...(userType === "participant" && {
        year_of_birth: parseInt(yearOfBirth),
      }),
    };

    let loginDataToSave = JSON.parse(JSON.stringify(loginData));
    loginDataToSave.userType = userType;
    delete loginDataToSave.pin;

    StorageManager.setLoginInformation(loginDataToSave);

    executeLoginAttempts(loginData);
  };

  const handleContinueClick = async (event: any) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    login();
  };

  const performPostLoginActions = async () => {
    try {
      if (isNewUser) {
        let data: SetPinData = {
          new_pin: pin,
        };

        await api(setPinService(data));
      }

      let profileData = await api(getProfile());
      remapPaymentMethods(profileData);

      dispatch(setProfile({ profile: profileData.data }));

      await api(acceptTermsAndConditions());

      navigate(callbackURL, { replace: true });
    } catch (error: any) {
      handleServerError(error);
    } finally {
      setLoading(false);
    }
  };

  const cancelRelogin = () => {
    StorageManager.removeLoginInformation();

    setIsNewUser(true);
    setTermsAccepted(false);
    setUserType("participant");
    setParticipantId("");
    setYearOfBirth("");
    setCode("");
    setPin("");
    navigate("/login");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: 800, margin: "auto" }}
    >
      <img
        data-test-id="logo"
        src="/images/nmible_logo.svg"
        alt="Nmible Logo"
        style={{ height: "30px", marginBottom: "16px" }}
      />

      <Grid>
        <CardContainer
          title={
            !isInactivityRedirect
              ? t("virtualCard_continueButtonTitle")
              : t("session_expired")
          }
          subtitle={t("payment_dynamic_description")}
          topRightContent={<LanguageButton />}
        >
          <FormErrorBox error={error} />

          <form>
            {!isInactivityRedirect && (
              <>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel data-test-id="login-as-label" component="legend">
                    {t("login_are_you_question")}
                  </FormLabel>

                  <RadioGroup
                    row
                    value={userType}
                    onChange={handleUserTypeChange}
                  >
                    <FormControlLabel
                      data-test-id="participant"
                      value="participant"
                      control={<Radio />}
                      label={t("login_participant")}
                    />

                    <FormControlLabel
                      data-test-id="carer"
                      value="carer"
                      control={<Radio />}
                      label={t("login_carer")}
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth>
                  <FormLabel
                    data-test-id="new-or-existing-label"
                    component="legend"
                  >
                    {t("login_new_or_existing")}
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      data-test-id="new-user"
                      control={
                        <Radio
                          checked={isNewUser}
                          onChange={() => setIsNewUser(true)}
                        />
                      }
                      label={t("auth_register")}
                    />
                    <FormControlLabel
                      data-test-id="existing-user"
                      control={
                        <Radio
                          checked={!isNewUser}
                          onChange={() => setIsNewUser(false)}
                        />
                      }
                      label={t("auth_signIn")}
                    />
                  </FormGroup>
                </FormControl>

                <TextField
                  data-test-id="participant-textfield"
                  label={t("auth_participantIdNumber")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={participantId}
                  name="username"
                  onChange={(e) => setParticipantId(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />

                {userType !== "carer" && (
                  <TextField
                    data-test-id="dateOfBirth-textfield"
                    label={t("auth_accountActivation_dateOfBirth")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={yearOfBirth}
                    name="date"
                    onChange={(e) => setYearOfBirth(e.target.value)}
                    inputProps={{ maxLength: 4 }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}

                {userType === "carer" && (
                  <TextField
                    data-test-id="carerId-textfield"
                    label={t("carerId_code")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                )}
              </>
            )}

            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {isNewUser ? t("auth_pin") : t("enterPin_title")}
              </InputLabel>

              <OutlinedInput
                onChange={(e) => setPin(e.target.value)}
                value={pin}
                data-test-id="pin-textfield"
                type={showPIN ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPIN}
                      edge="end"
                    >
                      {showPIN ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={isNewUser ? t("auth_pin") : t("enterPin_title")}
              />
            </FormControl>

            {isNewUser && (
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel htmlFor="outlined-adornment-confirm-password">
                  {t("confirmPin_title")}
                </InputLabel>

                <OutlinedInput
                  onChange={(e) => setConfirmPin(e.target.value)}
                  value={confirmPin}
                  data-test-id="confirm-pin-textfield"
                  type={showPIN ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPIN}
                        edge="end"
                      >
                        {showPIN ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("confirmPin_title")}
                />
              </FormControl>
            )}

            {!isInactivityRedirect && (
              <FormControlLabel
                control={
                  <Checkbox
                    data-test-id="accept-tc-checkbox"
                    checked={termsAccepted}
                    onChange={handleTermsChange}
                    name="termsAndConditions"
                    color="primary"
                  />
                }
                label={
                  <Typography
                    data-test-id="accept-tc-label"
                    variant="subtitle1"
                  >
                    {t("accept")} &nbsp;
                    <Typography
                      data-test-id="accept-tc-link"
                      variant="subtitle1"
                      component={Link}
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenTermsDialog();
                      }}
                    >
                      {t("terms_termsAndConditions")}.
                    </Typography>
                  </Typography>
                }
              />
            )}

            <LoadingButton
              data-test-id="submit-button"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              sx={{ mt: 2, mb: 2 }}
              loading={isLoading}
              onClick={handleContinueClick}
              disabled={!isFormValid} // Button will be disabled if form is not valid
            >
              {t("continue_text")}
            </LoadingButton>

            {(isInactivityRedirect || !isNewUser) && (
              <Typography align="center">
                <Button
                  data-test-id="forget-pin-button"
                  variant="text"
                  color="primary"
                  size="small"
                  sx={{ pl: 2, pr: 2 }}
                  onClick={() => setOpenForgottenPinDialog(true)}
                >
                  {t("changePin_forgetPin")}
                </Button>
              </Typography>
            )}

            {isInactivityRedirect && (
              <Button
                data-test-id="cancel-button"
                fullWidth
                onClick={() => cancelRelogin()}
                sx={{
                  color: "red",
                }}
              >
                {t("cancel")}
              </Button>
            )}
          </form>
        </CardContainer>
      </Grid>

      {/* Terms and Conditions Dialog */}
      <ConfirmationDialog
        testId="tc-dialog"
        maxWidth="md"
        showCancelIcon={true}
        fullWidth={true}
        open={openTermsDialog}
        title={t("terms_termsAndConditions")}
        message={
          <span
            style={{
              padding: 0,
              height: "60vh",
              overflow: "hidden",
              display: "block",
            }}
          >
            <TermsAndConditions />
          </span>
        }
        confirmButtonText={t("button_download_pdf")}
        confirmButtonIcon={<GetAppIcon />}
        onConfirm={handleDownloadPdf}
        cancelButtonText={t("button_close")}
        onCancel={handleCloseTermsDialog}
      />

      {/* Study ID Dialog */}
      <StudyIDDialog
        open={openStudyIDDialog}
        onClose={closeStudyIDDialog}
        studyID={studyID}
        setStudyID={setStudyID}
        onSubmit={handleStudyIDSubmit}
      />

      {/* Forgotten PIN Dialog */}
      <ConfirmationDialog
        testId="forgotten-pin-dialog"
        maxWidth="sm"
        open={openForgottenPinDialog}
        title={t("auth_forgotPinAlertTitle")}
        message={t("auth_forgotPinAlertMessage")}
        confirmButtonText={t("email_support")}
        cancelButtonText={t("button_close")}
        onConfirm={() => {
          window.location.href = `mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`;
        }}
        onCancel={() => setOpenForgottenPinDialog(false)}
      />
    </Box>
  );
}
