import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api, useAPI } from "../api/api";
import {
  getLatestPaymentMethod,
  getVirtualCard,
} from "../api/paymentMethodsService";
import {
  getCarerClaims,
  getClaims,
  getWaitingStipends,
} from "../api/stipendsServices";
import { usePageTitle } from "../common/TitleContext";
import {
  formatNumber,
  getLocalizedMessage,
  getSymbol,
} from "../common/helpers";
import { useErrorHandler } from "../common/hooks";
import SubmitClaimButton from "../components/Payment/SubmitClaimButton";
import ClaimCard from "../components/Stipend/ClaimCard";
import MyStipendCard from "../components/Stipend/MyStipendCard";
import {
  selectAvailablePaymentMethods,
  selectCurrency,
} from "../redux/slices/authSlice";
import {
  setWaitingStipends,
  updateCarerClaims,
  updateClaims,
} from "../redux/slices/claimsSlice";
import { setLatestPaymentMethod } from "../redux/slices/paymentMethodsSlice";
import { setVccData } from "../redux/slices/vccSlice";
import { RootState, store } from "../redux/store";
import StorageManager from "../services/storage";
import theme from "../theme";
import {
  ClaimsResponse,
  PaymentAccountDetails,
  PaymentType,
  VirtualCardResponse,
} from "../types/paymentTypes";
import { Stipend, WaitingStipendsResponse } from "../types/stipendTypes";
import CardContainer from "./CardContainer";
import ProtectYourIdentity from "./ProtectYourIdentity";
import TaxPreview from "./TaxPreview";
const PaymentsPage = () => {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();

  usePageTitle(t("tabBar_payments"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paymentMethodsData = useSelector(selectAvailablePaymentMethods);
  const isCarer = StorageManager.getLoginInformation()?.userType === "carer";

  const [isTaxOpen, setTaxOpen] = useState(false);
  const [isProtectYourIdentityOpen, setProtectYourIdentityOpen] =
    useState(false);

  const [shouldFetchVCC, setShouldFetchVCC] = useState(false);
  const currencySymbol = getSymbol(useSelector(selectCurrency)) ?? "";

  const {
    data: waitingStipendsData,
    loading: loadingWaitingStipends,
    error: waitingStipendsError,
  } = useAPI<WaitingStipendsResponse>(getWaitingStipends());

  useEffect(() => {
    if (!waitingStipendsError) {
      return;
    }

    handleServerError(waitingStipendsError);
  }, [waitingStipendsError]);

  const claimsData = useSelector((state: RootState) => state.claims.claims);
  const carerClaimsData = useSelector(
    (state: RootState) => state.claims.carerClaims
  );

  // Since the first thing we do is to start loading the claims, we set the initial loading flag to TRUE anyway, 
  // so we can fix a flicker effect on the payments page.
  const [loadingClaims, setLoadingClaims] = useState(true);
  const [loadingCarerClaims, setLoadingCarerClaims] = useState(true);

  const [claimsPage, setClaimsPage] = useState(1);
  const [carerClaimsPage, setCarerClaimsPage] = useState(1);

  const [hasMoreClaims, setHasMoreClaims] = useState(true);
  const [hasMoreCarerClaims, setHasMoreCarerClaims] = useState(true);

  useEffect(() => {
    loadClaims(1);
    loadCarerClaims(1)
  }, []);

  function loadClaims(page: number) {
    if (!hasMoreClaims) {
      setLoadingClaims(false);
      return;
    }

    setLoadingClaims(true);

    api<ClaimsResponse>(getClaims(page))
      .then((response) => {
        const claims = response.data.claims;

        dispatch(updateClaims({ claims: claims, page: page }));
        setClaimsPage(page);
        setHasMoreClaims(claims.length === 10);
      })
      .catch((error) => {
        handleServerError(error);
      })
      .finally(() => {
        setLoadingClaims(false);
      });
  }

  function loadCarerClaims(page: number) {
    if (!hasMoreCarerClaims || isCarer) {
      setLoadingCarerClaims(false);
      return;
    }

    setLoadingCarerClaims(true);

    api<ClaimsResponse>(getCarerClaims(page))
      .then((response) => {
        const claims = response.data.claims;

        dispatch(updateCarerClaims({ claims: claims, page: page }));
        setCarerClaimsPage(page);
        setHasMoreCarerClaims(claims.length === 10);
      })
      .catch((error) => {
        handleServerError(error);
      })
      .finally(() => {
        setLoadingCarerClaims(false);
      });
  }

  const {
    data: latestPaymentAccount,
    loading: loadingLatestPaymentAccount,
    error: latestPaymentAccountError,
  } = useAPI<PaymentAccountDetails>(getLatestPaymentMethod());

  useEffect(() => {
    if (!latestPaymentAccountError) {
      return;
    }

    const errorData: any = latestPaymentAccountError.response?.data;
    const errorCode: number = errorData.err_code;

    // Since BE returns 404 error when the used still has no payment account created, we use it to ignore the error handling.
    if (errorCode === 404) {
      return;
    }

    handleServerError(latestPaymentAccountError);
  }, [latestPaymentAccountError]);

  const {
    data: vccData,
    loading: isVCCLoading,
    error: vccError,
  } = useAPI<VirtualCardResponse>(getVirtualCard(), shouldFetchVCC);

  useEffect(() => {
    if (!vccError) {
      return;
    }

    // if 404, the user doesn't have a vcc
    if (vccError.response?.status == 404) {
      return;
    }

    handleServerError(vccError);
  }, [vccError]);

  useEffect(() => {
    if (latestPaymentAccount)
      dispatch(setLatestPaymentMethod(latestPaymentAccount));
  }, [latestPaymentAccount]);

  useEffect(() => {
    const hasVCC = paymentMethodsData.some(
      (method) => method.payment_type === PaymentType.VirtualCard
    );
    setShouldFetchVCC(hasVCC);
  }, [paymentMethodsData]);

  const profile = useSelector((state: RootState) => state.auth.profile);

  const isLocked = profile?.is_locked;
  const isElegableForStipend =
    profile?.available_claim_types.includes("Stipend") &&
    profile?.country.toLowerCase() === "us";

  const gatherTaxInfo = isElegableForStipend && !profile?.email;

  const paymentsMessage = getLocalizedMessage(
    import.meta.env.VITE_PAYMENTS_MESSAGE
  );

  useEffect(() => {
    if (vccData?.vccs[0]) {
      let cardInfo = vccData?.vccs[0];
      dispatch(setVccData(cardInfo));
    }
  }, [vccData, dispatch]);

  useEffect(() => {
    if (waitingStipendsData) {
      dispatch(setWaitingStipends(waitingStipendsData.claims));
    }
  }, [waitingStipendsData]);

  const handleClaimSelect = (claimId: number) => {
    navigate(`/payment/${claimId}/details`);
  };

  const handleCarerClaimSelect = (claimId: number) => {
    navigate(`/payment/${claimId}/details?owner=carer`);
  };

  const loadMoreClaims = () => {
    loadClaims(claimsPage + 1);
  };

  const loadMoreCarerClaims = () => {
    loadCarerClaims(carerClaimsPage + 1);
  };

  function isLoadingData(): boolean {
    return (
      loadingWaitingStipends ||
      loadingLatestPaymentAccount ||
      isVCCLoading ||
      (loadingClaims && claimsData.length === 0) ||
      (loadingCarerClaims && carerClaimsData.length === 0)
    );
  }

  function onClaim(stipend: Stipend) {
    // save selected claim
    if (latestPaymentAccount) {
      navigate(`/payment/submit/${stipend.id}/summary`);
    } else {
      navigate(`/payment/submit/${stipend.id}/payment-methods`);
    }
  }

  function onDecline(stipend: Stipend) {
    navigate(`/payment/${stipend.id}/decline`);
  }

  const renderStipendRow = (stipend: Stipend) => {
    return (
      <MyStipendCard
        key={stipend.id}
        stipend={stipend}
        currency={currencySymbol}
        onClaim={onClaim}
        onDecline={onDecline}
      />
    );
  };

  function onSubmitMileage() {
    const vehicleQuestions =
      store.getState().auth.profile?.distance_rule_screens ?? [];
    if (vehicleQuestions.length > 0 && !vehicleQuestions[0].selected_option) {
      navigate("/payment/submit/mileage/vehicle");
    } else {
      navigate("/payment/submit/mileage/visit");
    }
  }

  function onSubmitReceipt() {
    if (StorageManager.hideProtectYourIdentityMessage()) {
      navigate("/receipt/submit/upload");
    } else {
      setProtectYourIdentityOpen(true);
    }
  }

  if (isLoadingData())
    return (
      <Paper
        data-test-id="payments-skeleton"
        style={{ padding: "30px 16px" }}
        elevation={0}
      >
        <Skeleton
          variant="rounded"
          height={96}
          style={{ marginBottom: "10px" }}
        />
        <Skeleton
          variant="rounded"
          height={96}
          style={{ marginBottom: "10px" }}
        />
        <Skeleton
          variant="rounded"
          height={96}
          style={{ marginBottom: "10px" }}
        />
      </Paper>
    );

  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "initial", padding: "0px", width: "100%" }}
    >
      <Grid container spacing={"40px"}>
        <Grid item xs={12}>
          {!isLocked && (
            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "flex-start",
                  sm: "flex-end",
                },
              }}
            >
              <SubmitClaimButton
                onSubmitMileage={onSubmitMileage}
                onSubmitReceipt={onSubmitReceipt}
              />
            </Box>
          )}

          <section>
            {gatherTaxInfo && (
              <CardContainer>
                <Alert
                  severity="info"
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    border: "none",
                    px: 0,
                  }}
                >
                  <AlertTitle
                    data-test-id="tax-alert-description"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("tax_requiresTaxInfo")}
                  </AlertTitle>

                  <Button
                    data-test-id="tax-alert-button"
                    onClick={() => setTaxOpen(true)}
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                    }}
                    size="medium"
                  >
                    {t("findOutMore")}
                  </Button>
                </Alert>
              </CardContainer>
            )}

            {paymentsMessage && (
              <Alert data-test-id="payments-msg" severity="warning">
                {paymentsMessage}
              </Alert>
            )}

            {vccData?.vccs[0] && (
              <CardContainer title={t("claimsList_virtualCard")}>
                <Box
                  data-test-id="vcc"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/vcc-card")}
                >
                  <ListItem
                    dense
                    disableGutters
                    sx={{ padding: 0, cursor: "pointer" }}
                  >
                    <CreditCardRoundedIcon
                      data-test-id="vcc-icon"
                      sx={{ fontSize: 30 }}
                      style={{ color: "gray" }}
                    />
                    <ListItemText
                      sx={{ textAlign: "left", ml: 1 }}
                      data-test-id="vcc-balance"
                      primary={
                        <Typography variant="subtitle2" fontWeight="600">
                          {t("vcc_availableBalance")}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          data-test-id="vcc-balance-title"
                          variant="body2"
                          color="text.secondary"
                        >
                          {vccData.vccs[0]
                            ? `$ ${formatNumber(vccData.vccs[0].balance)}`
                            : "$ 0.00"}
                        </Typography>
                      }
                    />
                    <KeyboardArrowRightIcon color="action" />
                  </ListItem>
                </Box>
              </CardContainer>
            )}

            {(waitingStipendsData?.claims ?? []).length > 0 && (
              <CardContainer
                data-test-id="stipends-section"
                title={t("claim_stipend_sectionTitle")}
              >
                <List sx={{ pt: 0, pb: 0 }}>
                  {waitingStipendsData?.claims.map((stipend) => {
                    return renderStipendRow(stipend);
                  })}
                </List>
              </CardContainer>
            )}
          </section>

          {!isCarer && carerClaimsData.length > 0 && (
            <CardContainer
              data-test-id="carer-payments-section"
              title={t("claimDetail_YourCarerClaims")}
            >
              <List>
                {carerClaimsData.map((claim) => {
                  return (
                    <ClaimCard
                      key={claim.id}
                      claim={claim}
                      onClick={() => handleCarerClaimSelect(claim.id)}
                    />
                  );
                })}
              </List>
              {hasMoreCarerClaims && (
                <LoadingButton
                  data-test-id="load-more-carer-payments-button"
                  size="large"
                  variant="outlined"
                  color="secondary"
                  sx={{ display: "flex", margin: "auto" }}
                  onClick={loadMoreCarerClaims}
                  loading={loadingCarerClaims}
                  loadingPosition="start"
                  startIcon={<FileDownloadIcon />}
                >
                  {t("loadMore")}
                </LoadingButton>
              )}
            </CardContainer>
          )}

          {claimsData.length > 0 && (
            <CardContainer
              data-test-id="payments-section"
              title={t("claim_yourClaimsAndPayments")}
            >
              <List>
                {claimsData.map((claim) => {
                  return (
                    <ClaimCard
                      key={claim.id}
                      claim={claim}
                      onClick={() => handleClaimSelect(claim.id)}
                    />
                  );
                })}
              </List>

              {hasMoreClaims && (
                <LoadingButton
                  data-test-id="load-more-payments-button"
                  size="large"
                  variant="outlined"
                  color="secondary"
                  sx={{ display: "flex", margin: "auto" }}
                  onClick={loadMoreClaims}
                  loading={loadingClaims}
                  loadingPosition="start"
                  startIcon={<FileDownloadIcon />}
                >
                  {t("loadMore")}
                </LoadingButton>
              )}
            </CardContainer>
          )}

          {claimsData.length === 0 &&
            carerClaimsData.length === 0 &&
            (waitingStipendsData?.claims ?? []).length === 0 && (
              <Stack
                spacing={3}
                mt={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Alert
                  data-test-id="payments-empty-text"
                  severity="info"
                  style={{ width: "100%", color: theme.palette.primary.main }}
                >
                  {t("payments_empty")}
                </Alert>

                <img
                  data-test-id="payments-empty-icon"
                  src="/images/no_payments.svg"
                  alt="No Payments Icon"
                  style={{ width: 200, height: 200 }}
                />
              </Stack>
            )}

          <ProtectYourIdentity
            isOpen={isProtectYourIdentityOpen}
            onClose={() => setProtectYourIdentityOpen(false)}
            onContinue={() => {
              setProtectYourIdentityOpen(false);
              navigate("/receipt/submit/upload");
            }}
          ></ProtectYourIdentity>

          <TaxPreview
            isOpen={isTaxOpen}
            onClose={() => setTaxOpen(false)}
            onContinue={() => {
              setTaxOpen(false);
              navigate("/tax/onboarding");
            }}
          ></TaxPreview>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PaymentsPage;
