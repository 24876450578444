import AddCardIcon from "@mui/icons-material/AddCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Card, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const CreateVccCard: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Card
        data-test-id="create-vcc-container"
        variant="outlined"
        onClick={() => navigate(`/payment/submit/${id}/payment-methods/VCC/preview`)}
        sx={{ width: "100%", border: "1px solid grey", cursor: "pointer" }}
        className="mt-2"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={1}
          alignItems="center"
        >
          <Box display="flex" p={1} alignItems="center">
            <AddCardIcon
              data-test-id="create-vcc-icon"
              fontSize="large"
              sx={{ color: "#808080" }}
            />
            <Box display="flex" flexDirection="column" marginLeft={2}>
              <Typography
                data-test-id="create-vcc-title"
                variant="subtitle2"
                sx={{ textAlign: "left", fontWeight: "600" }}
              >
                {t("payment_noneOfAbove")}
              </Typography>
              <Typography
                data-test-id="create-vcc-description"
                variant="body2"
                color="text.secondary"
              >
                {t("payment_virtualCardOption")}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pl={5}
            gap={1}
          >
            <IconButton>
              <ArrowForwardIosIcon data-test-id="vcc-arrow" fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CreateVccCard;
