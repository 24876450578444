import {
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import CardContainer from "./CardContainer";
{
  //TODO: remove this page after integrating with TaxBandits
}
function TaxOnboardingPage() {
  const { t } = useTranslation();

  usePageTitle(t("tax_onboarding_irs_title"));
  const navigate = useNavigate();

  function onClose() {
    navigate("/");
  }

  function onContinue() {
    navigate("/tax/form");
  }

  return (
    <div>
      <article>
        <CardContainer title={t("tax_onboarding_irs_title")}>
          <Typography variant="body1">
            {t("tax_onboarding_irs_description")}
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              marginLeft: "20px",
              "& li": { display: "list-item" },
            }}
          >
            <ListItem>
              <ListItemText primary={t("tax_onboarding_irs_requirements_p1")} />
            </ListItem>
            <ListItem>
              <ListItemText primary={t("tax_onboarding_irs_requirements_p2")} />
            </ListItem>
            <ListItem>
              <ListItemText primary={t("tax_onboarding_irs_requirements_p3")} />
            </ListItem>
            <ListItem>
              <ListItemText primary={t("tax_onboarding_irs_requirements_p4")} />
            </ListItem>
            <ListItem>
              <ListItemText primary={t("tax_onboarding_irs_requirements_p5")} />
            </ListItem>
          </List>
        </CardContainer>
      </article>

      <article>
        <CardContainer title={t("tax_onboarding_security_title")}>
          <Typography variant="body1">
            {t("tax_onboarding_security_description_p1")}
          </Typography>
          <Typography variant="body1" pb={2}>
            {t("tax_onboarding_security_description_p2")}
          </Typography>
        </CardContainer>
      </article>

      <article>
        <CardContainer title={t("tax_onboarding_howItWorks_title")}>
          <List
            sx={{
              listStyleType: "decimal",
              marginLeft: "20px",
              "& li": { display: "list-item" },
            }}
          >
            <ListItem>
              <ListItemText
                primary={t("tax_onboarding_howItWorks_p1Title")}
                secondary={t("tax_onboarding_howItWorks_p1Description")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("tax_onboarding_howItWorks_p2Title")}
                secondary={t("tax_onboarding_howItWorks_p2Description")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("tax_onboarding_howItWorks_p3Title")}
                secondary={t("tax_onboarding_howItWorks_p3Description")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("tax_onboarding_howItWorks_p4Title")}
                secondary={t("tax_onboarding_howItWorks_p4Description")}
              />
            </ListItem>
          </List>
        </CardContainer>
      </article>

      <section>
        <Stack direction="row" justifyContent={"right"} spacing={2} mt={2}>
          <Button
            sx={{ textTransform: "initial" }}
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Skip
          </Button>
          <Button
            sx={{ textTransform: "initial", fontWeight: "bold" }}
            variant="contained"
            color="primary"
            onClick={onContinue}
          >
            {t("continue_text")}
          </Button>
        </Stack>
      </section>
    </div>
  );
}

export default TaxOnboardingPage;
