import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../api/api";
import paymentService from "../api/paymentService";
import { submitReceiptClaim } from "../api/stipendsServices";
import { usePageTitle } from "../common/TitleContext";
import { getSymbol } from "../common/helpers";
import { useErrorHandler } from "../common/hooks";
import { useVisits } from "../common/useVisits";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import { clearReceiptClaimData } from "../redux/slices/claimsSlice";
import { showToast } from "../redux/slices/toastSlice";
import { RootState } from "../redux/store";
import { Visit, dateString } from "../types/common";
import {
  CategoryAmount,
  ConversionResponse,
  ConvertCurrencyData,
  ReceiptClaimData,
} from "../types/paymentTypes";
import SummaryPageUI, {
  Action,
  DetailSection,
  VisitDetailSection,
} from "./SummaryPageUI";

const ReceiptSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();

  usePageTitle(t("claim_chooseClaimReceipt"));

  const [visit, setVisit] = useState<Visit | null>(null);
  const [isAccepting, setIsAccepting] = useState(false);
  const [conversionData, setConversionData] =
    useState<ConversionResponse | null>(null);

  const claim: ReceiptClaimData | null = useSelector(
    (state: RootState) => state.claims.receiptClaim
  );

  const receiptCurrency = useSelector(
    (state: RootState) => state.claims.receiptClaim?.currency ?? "USD"
  );

  const latestPaymentAccount = useSelector(
    (state: RootState) => state.paymentMethods.latestPaymentAccount!
  );
  const paymentCurrencySymbol = getSymbol(
    latestPaymentAccount.payment_currency
  );

  const { visits, isLoading: isLoadingVisits } = useVisits();

  const handleCurrencyConversion = async () => {
    if (!latestPaymentAccount) return;
    if (!claim?.total_amount) return;
    if (receiptCurrency === latestPaymentAccount?.payment_currency) return;
    const totalAmount = sumAmounts(claim.items ?? []);
    const conversionInput: ConvertCurrencyData = {
      source: receiptCurrency,
      to: latestPaymentAccount.payment_currency,
      amount: totalAmount,
      country_to_pay: latestPaymentAccount.payment_country_iso,
    };

    try {
      const response = await api(
        paymentService.convertCurrency(conversionInput)
      );
      setConversionData(response.data);
    } catch (error: any) {
      handleServerError(error);
    }
  };

  useEffect(() => {
    handleCurrencyConversion();
  }, [receiptCurrency, latestPaymentAccount]);

  useEffect(() => {
    if (claim?.visit_id) {
      let visit = visits.find((item) => {
        return item.id === claim?.visit_id;
      });
      if (visit) setVisit(visit);
    }
  }, [claim, visits.length]);

  const submitClaim = () => {
    if (!latestPaymentAccount) return;
    if (isAccepting) return;

    setIsAccepting(true);

    const claimData = {
      ...claim,
      payment_details: latestPaymentAccount,
      payment_country_iso: latestPaymentAccount.payment_country_iso,
      conversion_uuid: conversionData?.conversion_uuid,
    };

    api(submitReceiptClaim(claimData))
      .then((response) => {
        dispatch(showToast(t("claim_toastConfirmationMessage")));
        dispatch(clearReceiptClaimData());
        navigate("/payments");
      })
      .catch((error) => {
        console.error("Error sending your claim:", error);
        handleServerError(error);
      })
      .finally(() => {
        setIsAccepting(false);
      });
  };

  const handleDeclinePayment = () => {
    dispatch(clearReceiptClaimData());
    navigate("/");
  };

  if (!claim) {
    return <CircularProgress />;
  }

  const actions: Action[] = [
    {
      label: t("cancel"),
      onClick: handleDeclinePayment,
      color: "secondary",
      variant: "outlined",
      loading: false,
    },
    {
      label: t("claim_SubmitAnExpense"),
      onClick: submitClaim,
      color: "primary",
      variant: "contained",
      loading: isAccepting,
    },
  ];
  const detailSections: DetailSection[] =
    claim.items?.map((item) => {
      return {
        title: item.value,
        content: `${getSymbol(receiptCurrency)}${item.amount?.toFixed(2)}`,
      };
    }) ?? [];

  if (conversionData) {
    let conversionInfo: DetailSection[] = [];
    if (claim.items!.length > 1) {
      conversionInfo.push({
        title: t("claim_originalTotal"),
        content: `${getSymbol(
          conversionData.original_currency
        )}${conversionData.original_amount.toFixed(2)}`,
      });
    }
    conversionInfo.push({
      title: t("claim_conversionRate"),
      content: `${getSymbol(conversionData.original_currency)}1 = ${getSymbol(
        conversionData.requested_currency
      )}${conversionData.conversion_rate}`,
    });

    detailSections.push(...conversionInfo);
  }

  function sumAmounts(visitCategories: CategoryAmount[]): number {
    return visitCategories.reduce((total, category) => {
      return total + (category.amount || 0);
    }, 0);
  }

  const total = conversionData?.amount_to_pay ?? sumAmounts(claim.items ?? []);

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  const totalSection: DetailSection = {
    title: "Total",
    content: `${paymentCurrencySymbol}${total.toFixed(2)}`,
  };

  return (
    <SummaryPageUI
      title={t("claim_reviewYourClaim")}
      isLoading={isLoadingVisits}
      showChangePaymentMethodButton={true}
      subtitle={t("claim_reviewSubtitle")}
      detailSections={detailSections}
      visitSection={visitSection}
      actions={actions}
      totalSection={totalSection}
      latestPaymentAccount={latestPaymentAccount}
      topRightContent={<ReceiptImagePreview />}
    />
  );
};

export default ReceiptSummaryPage;
