import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { api } from "../api/api";
import { disableCarer, unlockCarer } from "../api/userService";
import { useErrorHandler } from "../common/hooks";
import theme from "../theme";
import { Carer } from "../types/common";
import ConfirmationDialog from "./ConfirmationDialog";
import StorageManager from "../services/storage";
interface CarerItemProps {
  carer: Carer;
  expanded: boolean;
  onUIChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  onLock: (carer: Carer) => void;
  onUnlock: (carer: Carer) => void;
  onDelete: (carer: Carer) => void;
}

interface DialogConfig {
  title: string;
  message: string;
  button: string;
  action: () => void;
}

enum Action {
  lock,
  unlock,
  delete,
}

const CarerItem: React.FC<CarerItemProps> = ({
  carer,
  expanded,
  onUIChange,
  onLock,
  onUnlock,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const participantId =
    StorageManager.getLoginInformation()?.participant_number?.trim() || "";
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogData, setConfirmationDialogData] =
    useState<DialogConfig>({
      title: "",
      message: "",
      button: "",
      action: () => { },
    });

  const lockDialogConfig: DialogConfig = {
    title: t("carerInvitation_lockAccountConfirmTitle"),
    message: t("carerInvitation_lockAccountConfirmMessage", {
      "0": carer.name,
    }),
    button: t("carerInvitation_lockThisAccount"),
    action: () => handleAction(Action.lock),
  };

  const deleteDialogConfig: DialogConfig = {
    title: t("carerInvitation_deleteAccountConfirmTitle"),
    message: t("carerInvitation_lockAccountConfirmMessage", {
      "0": carer.name,
    }),
    button: t("carerInvitation_deleteAccountConfirmButton"),
    action: () => handleAction(Action.delete),
  };

  const handleCopy = (text: string, message: string) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [executingAction, setExecutingAction] = useState(false);

  const handleAction = (actionType: Action) => {
    setConfirmationDialogOpen(false);
    setExecutingAction(true);
    let actionPromise;

    switch (actionType) {
      case Action.lock:
        actionPromise = api(disableCarer(carer.claimant_id));
        break;
      case Action.unlock:
        actionPromise = api(unlockCarer(carer.claimant_id));
        break;
      case Action.delete:
        actionPromise = api(disableCarer(carer.claimant_id));
        break;
      default:
        setExecutingAction(false);
        return;
    }

    actionPromise
      .then((response) => {
        invokeCallback(actionType);
      })
      .catch((error: any) => handleServerError(error as AxiosError))
      .finally(() => {
        setExecutingAction(false);
      });
  };

  function invokeCallback(actionType: Action) {
    switch (actionType) {
      case Action.lock:
        onLock(carer);
        break;
      case Action.unlock:
        onUnlock(carer);
        break;
      case Action.delete:
        onDelete(carer);
        break;
    }
  }

  const openConfirmationDialog = (config: DialogConfig) => {
    setConfirmationDialogData(config);
    setConfirmationDialogOpen(true);
  };

  const renderDeleteButton = () => {
    return (
      <LoadingButton
        data-test-id="invitation-delete-button"
        size="large"
        variant="contained"
        color="error"
        loading={executingAction}
        loadingPosition="start"
        startIcon={<HighlightOffIcon />}
        onClick={() => openConfirmationDialog(deleteDialogConfig)}
      >
        {t("carerInvitation_deleteAccountConfirmButton")}
      </LoadingButton>
    )
  }

  const renderStep = (stepNumber: number, text: string) => (
    <Box display="flex" mb={1}>
      <Box
        data-test-id={`invite-carer-step${stepNumber}`}
        sx={{
          width: 24,
          height: 24,
          minWidth: 24,
          minHeight: 24,
          borderRadius: "50%",
          backgroundColor: "primary.main",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 1,
          flexShrink: 0,
        }}
      >
        {stepNumber}
      </Box>
      <Typography>{text}</Typography>
    </Box>
  );

  const renderCarerInfo = (claimant_id: string, code: string) => (
    <Box
      component={"section"}
      sx={{
        backgroundColor: "#A25594",
        p: 1,
        borderRadius: 0.5,
        mt: 1.5,
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography
          data-test-id="carer-participant-id"
          variant="body1"
          fontWeight={"500"}
          color="white"
        >
          {t("auth_participantIdNumber")}: {claimant_id}
        </Typography>
        <IconButton
          onClick={() =>
            handleCopy(claimant_id.toString(), t("participant_id_copied"))
          }
        >
          <ContentCopyIcon
            data-test-id="copy-participant-id"
            style={{ color: "white" }}
          />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography
          data-test-id="carer-code"
          variant="body1"
          fontWeight={"500"}
          color="white"
        >
          {t("carerId_code")}: {code}
        </Typography>
        <IconButton onClick={() => handleCopy(code, t("carer_code_copied"))}>
          <ContentCopyIcon
            data-test-id="copy-carer-code"
            style={{ color: "white" }}
          />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <>
      <Accordion expanded={expanded} onChange={onUIChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              data-test-id="invitation-title"
              variant="h6"
              sx={{ flexShrink: 0 }}
            >
              {carer.name || t("carerInvitation_inviteNotUsed")}
            </Typography>
            {!isMobile && (
              <Typography
                variant="subtitle1"
                data-test-id="invitation-subtitle"
                sx={{
                  marginLeft: 2,
                  color: "text.secondary",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {carer.name
                  ? t("carerInvitation_manageTheirAccount")
                  : t("carerInvitation_shareActivationCode")}
              </Typography>
            )}
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          {carer.name ? (
            <Box>
              <Typography data-test-id="invitation-description">
                {t(
                  carer.is_locked
                    ? "carerInvitation_lockedAccountMessage_p1"
                    : "carerInvitation_acceptedInvitationMessage",
                  {
                    "0": carer.name,
                  }
                )}
              </Typography>

              {!carer.is_locked && renderCarerInfo(participantId, carer.code)}

              <Box sx={{ mt: 1.5 }}>
                {carer.can_delete ? (renderDeleteButton()) : (
                  <LoadingButton
                    data-test-id="invitation-lock-button"
                    size="large"
                    variant="contained"
                    color="error"
                    loading={executingAction}
                    loadingPosition="start"
                    startIcon={<LockIcon />}
                    onClick={() =>
                      carer.is_locked
                        ? handleAction(Action.unlock)
                        : openConfirmationDialog(lockDialogConfig)
                    }
                  >
                    {carer.is_locked ? "Unlock" : "Lock"} Account
                  </LoadingButton>
                )}
              </Box>
            </Box>
          ) : (
            <Box>
              {renderStep(1, t("carerInvitation_firstStep"))}
              {renderStep(2, t("carerInvitation_secondStep"))}
              {renderStep(3, t("carerInvitation_thirdStep"))}

              {renderCarerInfo(participantId, carer.code)}

              <Typography
                data-test-id="invitation-support"
                variant="body1"
                mt={1.5}
              >
                {t("claimDetail_haveAQuestion")}{" "}

                <Link data-test-id="invitation-support-link" to="/support">
                  {t("carerInvitation_visitSupport")}
                </Link>
              </Typography>

              {carer.can_delete && (renderDeleteButton())
              }
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <Snackbar
        data-test-id="copy-toast"
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message={snackbarMessage}
      />

      <ConfirmationDialog
        open={confirmationDialogOpen}
        title={confirmationDialogData.title}
        message={confirmationDialogData.message}
        confirmButtonText={confirmationDialogData.button}
        cancelButtonText={t("cancel")}
        onConfirm={confirmationDialogData.action}
        onCancel={() => setConfirmationDialogOpen(false)}
      />
    </>
  );
};

export default CarerItem;
