import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IOwnProps {
  onNext: () => void;
}

const InfoStep: React.FC<IOwnProps> = ({ onNext }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        data-test-id="close-account-onboarding-s1-title"
        variant="h5"
        mb={1}
      >
        {t("closeAccount_confirmation_section1_title")}{" "}
      </Typography>

      <Typography
        data-test-id="close-account-onboarding-s1-description1"
        variant="body2"
        mb={1}
      >
        {t("closeAccount_confirmation_section1_description1")}
      </Typography>
      <Typography
        data-test-id="close-account-onboarding-s1-description2"
        variant="body2"
        mb={1}
      >
        {t("closeAccount_confirmation_section1_description2")}
      </Typography>
      <Typography
        data-test-id="close-account-onboarding-s1-description3"
        variant="body2"
        mb={1}
      >
        {t("closeAccount_confirmation_section1_description3")}
      </Typography>
      <Typography
        data-test-id="close-account-onboarding-s1-description4"
        variant="body2"
        mb={1}
      >
        {t("closeAccount_confirmation_section1_description4")}
      </Typography>

      <Typography
        data-test-id="close-account-onboarding-s2-title"
        variant="h5"
        mb={1}
      >
        {t("closeAccount_confirmation_section2_title")}{" "}
      </Typography>

      <Typography
        data-test-id="close-account-onboarding-s2-description"
        variant="body2"
      >
        {t("closeAccount_confirmation_section2_description")}
      </Typography>

      <Button
        data-test-id="close-account-onboarding-button"
        sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={onNext}
      >
        {t("continue_text")}
      </Button>
    </>
  );
};

export default InfoStep;
