import { LoadingButton } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TaxInfo, setTaxInfo, updateTaxInfo } from "../api/AuthService";
import { api } from "../api/api";
import { usePageTitle } from "../common/TitleContext";
import { useErrorHandler } from "../common/hooks";
import useDebounceEffect from "../components/AddressSearch/utils/useDebounceEffect";
import { validateEmail } from "../components/LoqateValidationUtils";
import { ValidationStatus } from "../components/ValidationTypes";
import { RootState } from "../redux/store";
import { Profile } from "../types/common";
import CardContainer from "./CardContainer";
{
  //TODO: remove this page after integrating with TaxBandits
}
const fullNameRegex =
  /^[a-zA-Z0-9_‘.-]{2,}\s+([a-zA-Z0-9_‘.-]{2,})((\s+[a-zA-Z0-9_‘.-]{2,})+)?$/;

enum PageMode {
  create,
  update,
}
enum Delivery {
  paper,
  electronic,
}

function TaxFormPage() {
  const { t } = useTranslation();

  const profile: Profile = useSelector(
    (state: RootState) => state.auth.profile!
  );

  const mode: PageMode =
    !profile.email && !profile.name ? PageMode.create : PageMode.update;
  const [isLoadingPaper, setLoadingPaper] = useState(false);
  // const [isLoadingElectronic, setLoadingElectronic] = useState(false);
  const navigate = useNavigate();

  usePageTitle(t("tax_form_title"));
  const { handleServerError } = useErrorHandler();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
    if (!fullNameRegex.test(event.target.value)) {
      setFullNameError(t("carerName_subTitle"));
    } else {
      setFullNameError("");
    }
  };

  // Debounced effect for email validation
  useDebounceEffect(
    async () => {
      const status = await validateEmail(email);
      if (status !== ValidationStatus.VALID) {
        setEmailError(t("error_email"));
      } else {
        setEmailError("");
      }
    },
    500,
    [email]
  );

  const handleSubmit = (delivery: Delivery) => {
    if (!fullName || !email || fullNameError || emailError) {
      // Handle validation error
      return;
    }

    // Send tax info
    const taxInfo: TaxInfo = {
      full_name: fullName,
      email: email,
      is_paper_copy_requested: delivery === Delivery.paper,
    };
    sendTaxInfo(taxInfo, delivery);
  };

  function sendTaxInfo(taxInfo: TaxInfo, delivery: Delivery) {
    startLoading(delivery);

    api(taxInfoRequest(taxInfo))
      .then(() => {
        navigate("/email/sent");
      })
      .catch((error) => {
        handleServerError(error);
      })
      .finally(() => {
        endLoading(delivery);
      });
  }

  function startLoading(delivery: Delivery) {
    if (delivery === Delivery.electronic) {
      // setLoadingElectronic(true);
    } else {
      setLoadingPaper(true);
    }
  }

  function endLoading(delivery: Delivery) {
    if (delivery === Delivery.electronic) {
      // setLoadingElectronic(false);
    } else {
      setLoadingPaper(false);
    }
  }

  function taxInfoRequest(taxInfo: TaxInfo): AxiosRequestConfig {
    if (mode === PageMode.create) return setTaxInfo(taxInfo);
    return updateTaxInfo(taxInfo);
  }

  return (
    <CardContainer>
      <TextField
        label={t("fullName")}
        variant="outlined"
        value={fullName}
        onChange={handleFullNameChange}
        error={!!fullNameError}
        helperText={fullNameError}
        fullWidth
        margin="normal"
      />
      <TextField
        label={t("email")}
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!emailError}
        helperText={emailError}
        fullWidth
        margin="normal"
      />
      <Typography
        variant="body2"
        color={"gray"}
        sx={{ textAlign: "center", mt: 8, mb: 1 }}
      >
        {t("tax_form_description")}
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}
      >
        <LoadingButton
          type="submit"
          variant="outlined"
          color="primary"
          fullWidth
          size="large"
          sx={{ mt: 2 }}
          loading={isLoadingPaper}
          onClick={() => handleSubmit(Delivery.paper)}
        >
          {t("tax_form_paperCopyButton")}
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          size="large"
          sx={{ mt: 2 }}
          loading={isLoadingPaper}
          onClick={() => handleSubmit(Delivery.electronic)}
        >
          {t("tax_form_electronicButton")}
        </LoadingButton>
      </Box>
    </CardContainer>
  );
}

export default TaxFormPage;
