import ReplayIcon from "@mui/icons-material/Replay";
import { Button, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { configService } from "../services/configService";

interface ErrorProps {
  icon: string;
  statusCode?: string | number;
  title: string;
  message: string;
  buttonAction: () => void;
  isRefreshBtn?: boolean;
  buttonText?: string;
}

const Error: React.FC<ErrorProps> = ({
  icon,
  statusCode,
  title,
  message,
  buttonAction,
  isRefreshBtn = true,
  buttonText,
}) => {
  const { t } = useTranslation();
  const showSupport = configService.config.showSupport;
  const supportEmail = configService.config.supportEmail;

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      <img
        data-test-id="error-icon"
        src={icon}
        alt="Error Icon"
        style={{ width: 148, height: 148, marginBottom: "40px" }}
      />
      <Typography
        data-test-id="error-status-code"
        variant="subtitle2"
        fontWeight={500}
        color="text.secondary"
      >
        {statusCode}
      </Typography>
      <Typography
        data-test-id="error-title"
        variant="h6"
        fontWeight={500}
        color="text.primary"
        style={{ margin: "20px 0" }}
      >
        {title}
      </Typography>
      <Typography data-test-id="error-message" variant="body1">
        {message}
      </Typography>
      <Box sx={{ margin: "30px 0" }}>
        <Button
          data-test-id="error-button"
          size="large"
          variant="contained"
          color="primary"
          onClick={buttonAction}
        >
          {isRefreshBtn && <ReplayIcon style={{ marginRight: "10px" }} />}
          {isRefreshBtn ? t("retry") : buttonText}
        </Button>
      </Box>
      {showSupport && (
        <>
          <Typography
            data-test-id="help-button"
            variant="subtitle2"
            fontWeight={500}
            color="#000000"
          >
            {t("participantIdHelp_subtitle")}
          </Typography>
          <Typography
            data-test-id="help-email"
            variant="subtitle2"
            fontWeight={500}
            color="#000000"
          >
            {supportEmail}
          </Typography>
        </>
      )}
    </Container>
  );
};

export default Error;
