interface AppConfig {
  supportEmail: string;
  showSupport: boolean;
}

class ConfigService {
  config: AppConfig;

  constructor() {
    this.config = {
      supportEmail: import.meta.env.VITE_SUPPORT_EMAIL ?? "",
      showSupport: import.meta.env.VITE_SHOW_SUPPORT === "true",
    };
  }
}

export const configService = new ConfigService();
